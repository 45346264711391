import { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import Grid from '@mui/material/Grid';
import palette from '../../theme/palette';
import logo from '../../resources/gains-icon-logo.png';

const ChatTextItem = forwardRef(({text, isUser}, ref) => {
return(
		<ListItem ref ={ref} sx={{backgroundColor:isUser ? palette.gains_gray_colors.lighter : "white"}}>
			<Grid container justifyContent="center" sx={{width: "100%"}}>
				<Grid container alignItems="center" justifyContent="left" sx ={{width: "70%"}}>
					<Grid item sx={{width: "35px"}} xs = {1} >{
							isUser ? <FontAwesomeIcon icon={faUser} color = {palette.gains_primary_colors.main} style={{fontSize: "24px", paddingRight: "10px"}} /> : <img style={{height:"20px", paddingRight: "10px"}} src={logo} alt =""/>
						}
					</Grid>
					<Grid item xs = {11}>
						<ListItemText align="start" primary={text}/>
					</Grid>
				</Grid>
			</Grid>
		</ListItem>
	)
});

export default ChatTextItem;