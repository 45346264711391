import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import SimpleLayout from './layouts/simple';
// pages
import Page404 from './pages/Page404';
import ChatAppPage from './pages/ChatAppPage/ChatAppPage';

// ----------------------------------------------------------------------

export default function Router() {
    const routes = useRoutes([
        {
			path:"*",
            element: <ChatAppPage/>,
        }
    ]);

    return routes;
}
