const envObj = {

    // UAT
    "chatbot": {
        "REACT_APP_API_URL": "https://chatbot-app-service-backend.azurewebsites.net/api/gainschat/"
    },

    // Data Science
    "gainschat": {
        "REACT_APP_API_URL": "https://gainsight.azurewebsites.net/api/gainschat/"
    },

    // Local
    "localhost": {
        "REACT_APP_API_URL": "http://localhost:8080/api/gainschat/"
    }
}

let environment = window.location.origin.split(/\/\//)[1].split("-")[0]
if (environment.indexOf('localhost') !== -1) {
    environment = 'localhost'    
}


const RUN_TIME_ENV = envObj[environment]
console.log('Chatbot runtime environment ', environment)

export default RUN_TIME_ENV


