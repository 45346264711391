import React, { useState, useRef, useEffect} from 'react';
import { Cookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentMedical, faComments } from '@fortawesome/free-solid-svg-icons';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import Fab from '@mui/material/Fab';
import ChatTextItem from '../../components/listItem/ChatTextItem';
import logo from '../../resources/gains-color-logo.svg'
import palette from '../../theme/palette';
import {getChatAnswer} from '../../services/chatService';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';

export default function ChatAppPage() {

	const cookies = new Cookies();
	 // Get all cookies
	const allCookies = cookies.getAll();

	 // Print all cookies to the console
	console.log('----all cookies', allCookies);

	const [text, setText] = useState("");
	const scrollRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	// when loading history just replace localHistory with stored history
	const [localHistory, setLocalHistory] = useState([]);
	const onChangeText = (event) => {
		setText(event.target.value);
	}
	const onSubmit = () => {
		
		const newLocalHistory = [...localHistory];
		newLocalHistory.push(<ChatTextItem key={newLocalHistory.length} ref={scrollRef} text = {text} isUser/>)
		setIsLoading(true);
		setLocalHistory(newLocalHistory);
		getChatAnswer(text, (answer) => {
			const newLocalHistoryTwo = [...newLocalHistory];
			newLocalHistoryTwo.push(<ChatTextItem key={newLocalHistoryTwo.length} ref={scrollRef} text = {answer}/>);
			setIsLoading(false);
			setLocalHistory(newLocalHistoryTwo);
		});
	}
	const handleKeyDown = (e) => {
		if (e.key === 'Enter' && text.length > 0) {
			onSubmit();
		}
	}
	const newChat = () => {
		setLocalHistory([]);
	}
	useEffect(() => {
		if (scrollRef.current) {
		  scrollRef.current.scrollIntoView({ behaviour: "smooth" });
		}
	  }, [localHistory]);

	return(
		<div>
		{isLoading ? <LoadingSpinner /> : null}
        <Grid container sx={{height:'5vh', paddingLeft: "10px"}} alignItems="center">
            <Grid item sx={{paddingTop: "6px", paddingRight:"5px"}}>
				<img style={{height: "24px"}} src={logo} alt=""/>
            </Grid>
			<Grid item>
				<Typography sx={{width: "10px", fontSize: "20px"}}>Chat</Typography>
			</Grid>
        </Grid>
        <Grid container component={Paper} sx = {{width: '100%', height: '95vh'}}>
            <Grid item xs={1.5} sx ={{borderRight: '1px solid #e0e0e0'}}>
                <List>
                    <ListItem key="history-bar">
						<FontAwesomeIcon icon={faComments} color = {palette.gains_gray_colors.black} style={{fontSize: "18px",paddingRight: "10px"}} />
                        <ListItemText primary="Chat History"/>
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={10.5}>
                <Grid container sx={{width: '100%', height:"95vh", overflowY: "hidden"}}>
					<Grid item xs ={12} sx = {{height: "82vh"}}>
						<List style={{scrollBehavior: 'smooth'}} sx = {{overflowY: 'auto', height: "100%", padding:"0px"}}>
							{localHistory}
						</List>
					</Grid>
					<Grid item xs = {12}>
						<Divider />
						<Grid 
							container 
							justifyContent="center" 
							alignItems="center" 
							sx={{height:"13vh"}}>
							<Grid 
								item 
								sx = {{paddingRight: "10px"}}>
								<Fab disabled = {isLoading} onClick={newChat} size="small" sx ={{boxShadow:"none", backgroundColor: "white" , color: palette.gains_primary_colors.main}}><FontAwesomeIcon icon={faCommentMedical} color = {palette.gains_primary_colors.main} style={{fontSize: "24px"}} /></Fab>
							</Grid>
							<Grid item xs={6}>
								<Grid container sx = {{flexWrap:"nowrap"}}>
									<Grid item xs = {10}>
										<TextField disabled = {isLoading} size="small" value={text} onKeyDown={handleKeyDown} onChange={onChangeText} id="outlined-basic-email" label="Ask a question" fullWidth />
									</Grid>
									<Grid item sx = {{paddingLeft:"10px"}}>
										<Button disabled = {text.length === 0 || isLoading} sx={{backgroundColor: palette.gains_primary_colors.main, fontSize: "18px", padding: "5px 10px", textTransform: "none"}} onClick = {onSubmit} variant="contained">Submit</Button>
									</Grid>
								</Grid>
								<Typography sx = {{paddingLeft: "10px", fontSize: "12px", fontWeight: 400}} color = {palette.gains_gray_colors.black}>GAINS may produce inaccurate information about people, places, or facts.</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
        </Grid>
      </div>
	)
}