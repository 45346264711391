import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './theme/css/base.css'
import React, { useState, useEffect } from 'react';
import { Cookies } from 'react-cookie';
import RUN_TIME_ENV from './services/config';
// routes
import Router from './routes';
// components


const BASE_URL = `${RUN_TIME_ENV.REACT_APP_API_URL}login`

export default function App() {

    const [token, setToken] = useState()
  
    const user = {
      "username": "super_user",
      "password": "super_password"
    }

    useEffect(()=>{
      fetch(BASE_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)        
      })
      .then(response=>{

        console.log(response)
        console.log([...response.headers.entries()])
      
        const res = response.json()
        console.log(res)
        if (response.ok) {
          return res        
        }
        throw response
      })
      .then(data=>{
        setToken(data.token)
        localStorage.setItem("token", data.token)
        console.log('---------token----', localStorage.getItem("token"))
      })
      .catch(error=>{
        console.log('---------------',error)      
      })
    }, [])

  return (
    <HelmetProvider>
      <BrowserRouter>
          <Router />
      </BrowserRouter>
    </HelmetProvider>
  );
}
