import axios from 'axios'
// import mock from '../utils/mockServer'

import RUN_TIME_ENV from './config';

// mock()
export function getChatAnswer(question, callback) {

	console.log('-----getChatAnswer----token----', localStorage.getItem("token"))

	axios.post(`${RUN_TIME_ENV.REACT_APP_API_URL}chat`, {
	  	"question": question,
		"token": localStorage.getItem("token")
	}).then(response => {
		if(response.status === 200){
			const answer = response.data.answer;
			if(answer === ""){
				callback("Hmm, sorry I have no answer to that question")
			}else{
				callback(answer)
			}
		}else{
			callback("No response from server, Try again")
		}
	})	
	const sampleResponse = {
		question:"how many model plausibility categories are there ?", 
		answer: "There are two model plausibility categories: one for models that generate a non-zero forecast and one for models that generate a zero forecast."};
    return sampleResponse.answer;
}